import {
  Box,
  Button,
  Flex,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import DisabledOverlay from 'components/DisabledOverlay';
import { PercentageNumberInput, TwNumberInput } from 'components/NumberInput';
import { get as _get, cloneDeep } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

const tableConfig = [
  {
    title: 'Direct Marketing',
    propName: 'directMarketing',
    dataPath: 'profit_and_loss.direct_marketing_spend',
    element: TwNumberInput,
  },
  {
    title: 'Draw',
    propName: 'draw',
    dataPath: 'twelve_balance.draw',
    element: TwNumberInput,
  },
  {
    title: 'Existing Cohort Revenue',
    propName: 'existingCohortRevenue',
    dataPath: 'profit_and_loss.revenue_from_existing_cohorts',
    element: TwNumberInput,
  },
  {
    title: 'Cumulative ROAS (%)',
    propName: 'roas',
    dataPath: 'roas',
    element: PercentageNumberInput,
    indexDataStart: 0,
    precision: 2,
  },
  {
    title: 'Gross Profit Margin (%)',
    propName: 'grossProfitMargin',
    dataPath: 'profit_and_loss.gross_profit_margin',
    element: PercentageNumberInput,
    precision: 2,
  },
  {
    title: 'Overhead',
    propName: 'overhead',
    dataPath: 'profit_and_loss.overhead',
    element: TwNumberInput,
  },
  {
    title: 'Payroll',
    propName: 'payroll',
    dataPath: 'profit_and_loss.payroll',
    element: TwNumberInput,
  },
  {
    title: 'Non-Operating Income',
    propName: 'nonOperatingIncome',
    dataPath: 'profit_and_loss.non_operating_income',
    element: TwNumberInput,
  },
  {
    title: 'Non-Operating Expenses',
    propName: 'nonOperatingExpenses',
    dataPath: 'profit_and_loss.non_operating_expenses',
    element: TwNumberInput,
  },
];

const CashflowModelDrivers = forwardRef(
  ({ modelResults, onDriversChange, roasVector }, ref) => {
    const [drivers, setDrivers] = useState();
    const [initialDrivers, setInitialDrivers] = useState();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isUseInModel, setIsUseInModel] = useState(true);

    useEffect(() => {
      if (!modelResults) {
        setDrivers(null);
        setInitialDrivers(null);
        return;
      }

      const driversData = { ...modelResults, ...{ roas: roasVector } };

      const _drivers = tableConfig.reduce((acc, config) => {
        const row = _get(driversData, config.dataPath);

        const indexDataStart =
          config.indexDataStart !== undefined
            ? config.indexDataStart
            : row.length - 12;

        const data = row.slice(indexDataStart).map((item) => ({
          date: moment({
            year: item.date.year,
            month: item.date.month - 1,
            day: item.date.day,
          }).format('YYYY-MM-DD'),
          amount: Math.abs(item.amount),
        }));
        acc[config.propName] = {
          factor: null,
          data,
        };
        return acc;
      }, {});
      setDrivers(cloneDeep(_drivers));
      setInitialDrivers(cloneDeep(_drivers));
    }, [modelResults]);

    useEffect(() => {
      onDriversChange(drivers);
    }, [drivers]);

    useEffect(() => {
      if (isUseInModel) {
        onDriversChange(drivers);
      } else {
        onDriversChange(null);
      }
    }, [isUseInModel]);

    const getHeaderLabels = () => {
      return Array.from({ length: 12 }).map((_, index) => `MOB.${index}`);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          generateCsvData: () => {
            const headers = ['Driver', 'Factor', ...getHeaderLabels()];

            const data = tableConfig.map((config) => {
              return [
                config.title,
                drivers[config.propName].factor,
                ...drivers[config.propName].data.map((item) =>
                  isNaN(item.amount) ? null : item.amount
                ),
              ];
            });

            return [headers, ...data];
          },
        };
      },
      [drivers]
    );

    if (!modelResults || !drivers) {
      return null;
    }

    const onFactorChange = (propName, factor) => {
      setDrivers((prev) => {
        let _drivers = { ...prev };

        if (!factor) {
          _drivers[propName].data = initialDrivers[propName].data;
          return _drivers;
        }

        for (let i = 0; i < _drivers[propName].data.length; i++) {
          if (!_drivers[propName].data[i].amount) {
            continue;
          }

          const amount =
            i === 0
              ? _drivers[propName].data[i].amount
              : _drivers[propName].data[i - 1].amount;

          _drivers[propName].data[i].amount = amount * factor;
        }

        return _drivers;
      });
    };

    return (
      <>
        <Flex gap={5} align={'center'}>
          <Flex align={'center'}>
            <Button
              display={'block'}
              minW={'20px'}
              h={'20px'}
              p={0}
              bg={'inherit'}
              _hover={{ bg: 'inherit' }}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? '-' : '+'}
            </Button>
            <Text fontWeight={800}>Drivers</Text>
          </Flex>
          <Flex gap={1} align={'center'} fontSize={'sm'}>
            <Switch
              id="useInModel"
              isChecked={isUseInModel}
              onChange={(event) => {
                setIsUseInModel(event.target.checked);
              }}
            />
            <Text>Use in model </Text>
          </Flex>
        </Flex>
        {isExpanded && (
          <Box position={'relative'}>
            {!isUseInModel && <DisabledOverlay />}
            <TableContainer mt={4}>
              <Table
                variant={'simple'}
                sx={{
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                }}
                __css={{
                  'td, th': {
                    padding: '5px',
                    minWidth: '100px',
                    whiteSpace: 'nowrap',
                  },
                  th: {
                    fontWeight: '800',
                    color: 'inherit',
                    borderBottom: '1px solid',
                    borderTop: '1px solid',
                    borderRight: '1px solid',
                  },
                  td: {
                    fontSize: 'sm',
                    borderBottom: '1px solid',
                    borderRight: '1px solid',
                  },
                  'td:first-of-type, th:first-of-type': {
                    borderLeft: '1px solid',
                  },
                }}
              >
                <Thead>
                  <Tr>
                    <Th position={'sticky'} left={0} bg={'white'} zIndex={1}>
                      Driver
                    </Th>
                    <Th>Factor</Th>
                    {getHeaderLabels().map((label, index) => (
                      <Th key={index}>{label}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {tableConfig.map((config) => (
                    <Tr key={config.propName}>
                      <Td position={'sticky'} left={0} bg={'white'} zIndex={1}>
                        {config.title}
                      </Td>
                      <Td>
                        <TwNumberInput
                          value={drivers[config.propName].factor}
                          variant={'inlineTable'}
                          onChange={(factor) =>
                            setDrivers((prev) => {
                              const _drivers = { ...prev };
                              _drivers[config.propName].factor = factor;
                              return _drivers;
                            })
                          }
                          onBlur={(factor) =>
                            onFactorChange(config.propName, factor)
                          }
                        />
                      </Td>
                      {drivers[config.propName].data.map((item, index) => (
                        <Td key={index}>
                          <config.element
                            variant={'inlineTable'}
                            value={item.amount}
                            precision={config.precision || 0}
                            onChange={(value) => {
                              setDrivers((prev) => {
                                const _drivers = { ...prev };
                                _drivers[config.propName].data[index].amount =
                                  value;
                                return _drivers;
                              });
                            }}
                          />
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </>
    );
  }
);

CashflowModelDrivers.propTypes = {
  modelResults: PropTypes.object,
  onDriversChange: PropTypes.func,
  roasVector: PropTypes.array,
};

export default CashflowModelDrivers;
